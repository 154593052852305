import React, { useState, useEffect } from "react";
import "./css/OneProduct.css";
import { Carousel } from "react-responsive-carousel";
import { useTranslation } from "react-i18next";

import {useLocation} from "react-router-dom";

function OneProduct({ backgroundImage, title, content, images, table, descriptions }) {
    const { t } = useTranslation();
    // const location = useLocation();
    const [selectedItem, setSelectedItem] = useState(0);

    // useEffect(() => {
    //     // setSelectedItem(0);
    // }, [location.pathname]);

    return (
        <div className="one-product">
            <div className="one-product-container">
                <div
                    className="one-product-header-image"
                    style={{ backgroundImage: "url(" + backgroundImage + ")" }}
                >
                    <div className="one-product-title-container">
                        <h1>{title}</h1>
                    </div>
                </div>

                <div className="one-product-content-container">
                    <div className="one-product-content">
                        <div className="carousel-container2">
                            {" "}
                            <Carousel
                                axis="horizontal"
                                autoPlay={true}
                                dynamicHeight={true}
                                infiniteLoop={true}
                                stopOnHover={false}
                                swipeable={true}
                                transitionTime={1000}
                                renderThumbnails={false}
                                renderThumbs={() => {}}
                                onClick={() => {}}
                                // selectedItem={selectedItem}
                                onChange={(index) => {
                                    setSelectedItem(index);
                                }}
                                interval={4750}

                            >
                                {/* <div key={"1"}>
                                    <img
                                        // className={"carousel-background"}
                                        src={images}
                                        alt=""
                                    />
                                </div> */}
                                {images?.map((el) => {
                                    return <div key={el} className="carousel-image-container">
                                        <div className="carousel-image-description">
                                            {descriptions?.length > selectedItem ? descriptions[selectedItem] : ""}
                                        </div>
                                        <img
                                            className={"carousel-image"}
                                            src={el}
                                            alt=""
                                        />
                                    </div>
                                })}
                            </Carousel>
                        </div>

                        {/* <img src={image} alt="" /> */}
                        <div className="one-product-content-texts">
                            <div className="one-product-content-texts-content">
                            {content?.map((el, index) => {
                                return <p key={index} dangerouslySetInnerHTML={{ __html: el }}></p>;
                            })}
                            </div>
                            {table == "ahsap-kesen" ? (
                                <table className={"ahsap-kesen"}>
                                    <tr>
                                        <th>{t("width")}</th>
                                        <th>{t("thickness")}</th>
                                    </tr>

                                    <tr>
                                        <td>10 mm</td>
                                        <td>0.60 mm</td>
                                    </tr>
                                    <tr>
                                        <td>15 mm</td>
                                        <td>0.70 mm</td>
                                    </tr>
                                    <tr>
                                        <td>20 mm</td>
                                        <td>0.70 mm</td>
                                    </tr>
                                    <tr>
                                        <td>25 mm</td>
                                        <td>0.70 mm</td>
                                    </tr>
                                    <tr>
                                        <td>30 mm</td>
                                        <td>0.70 mm</td>
                                    </tr>
                                    <tr>
                                        <td>35 mm</td>
                                        <td>0.80 mm</td>
                                    </tr>
                                    <tr>
                                        <td>35 mm</td>
                                        <td>1.00 mm</td>
                                    </tr>
                                    <tr>
                                        <td>40 mm</td>
                                        <td>0.80 mm</td>
                                    </tr>
                                    <tr>
                                        <td>40 mm</td>
                                        <td>1.00 mm</td>
                                    </tr>
                                    <tr>
                                        <td>45 mm</td>
                                        <td>0.90 mm</td>
                                    </tr>
                                    <tr>
                                        <td>45 mm</td>
                                        <td>1.00 mm</td>
                                    </tr>
                                    <tr>
                                        <td>50 mm</td>
                                        <td>0.90 mm</td>
                                    </tr>
                                    <tr>
                                        <td>50 mm</td>
                                        <td>1.00 mm</td>
                                    </tr>
                                    <tr>
                                        <td>50 mm</td>
                                        <td>1.07 mm</td>
                                    </tr>
                                    <tr>
                                        <td>60 mm</td>
                                        <td>1.00 mm</td>
                                    </tr>
                                    <tr>
                                        <td>70 mm</td>
                                        <td>1.10 mm</td>
                                    </tr>
                                    <tr>
                                        <td>80 mm</td>
                                        <td>1.10 mm</td>
                                    </tr>
                                    <tr>
                                        <td>85 mm</td>
                                        <td>1.20 mm</td>
                                    </tr>
                                    <tr>
                                        <td>100 mm</td>
                                        <td>1.20 mm</td>
                                    </tr>
                                    <tr>
                                        <td>100 mm</td>
                                        <td>1.30 mm</td>
                                    </tr>
                                    <tr>
                                        <td>120 mm</td>
                                        <td>1.20 mm</td>
                                    </tr>
                                    <tr>
                                        <td>120 mm</td>
                                        <td>1.30 mm</td>
                                    </tr>
                                    <tr>
                                        <td>150 mm</td>
                                        <td>1.30 mm</td>
                                    </tr>
                                    <tr>
                                        <td>150 mm</td>
                                        <td>1.50 mm</td>
                                    </tr>
                                </table>
                            ) : (
                                " "
                            )}

                            {table == "et-ve-balik" ? (
                                <table>
                                    <tr>
                                        <th>{t("width")}</th>
                                        <th>{t("thickness")}</th>
                                    </tr>

                                    <tr>
                                        <td>16 mm</td>
                                        <td>0.50 mm</td>
                                    </tr>
                                    <tr>
                                        <td>19 mm</td>
                                        <td>0.50 mm</td>
                                    </tr>
                                    <tr>
                                        <td>19 mm</td>
                                        <td>0.56 mm</td>
                                    </tr>
                                </table>
                            ) : (
                                ""
                            )}
                            {table == "disleri-sertlestirilmis-testereler" ? (
                                <table>
                                    <tr>
                                        {/* <th>Genişlik (W)</th>
                                        <th>Kalınlık (T)</th>
                                        <th>Diş Aralığı (T)</th> */}
                                        <th>{t('width')}</th>
                                        <th>{t('thickness')}</th>
                                        <th>{t('tooth-spacing')}</th>
                                    </tr>

                                    <tr>
                                        <td>32 mm</td>
                                        <td>1 mm</td>
                                        <td>22 mm</td>
                                    </tr>
                                    <tr>
                                        <td>38 mm</td>
                                        <td>1 mm</td>
                                        <td>22 mm</td>
                                    </tr>
                                    <tr>
                                        <td>50 mm</td>
                                        <td>1.10 mm</td>
                                        <td>22 mm</td>
                                    </tr>
                                    <tr>
                                        <td>19 mm</td>
                                        <td>0.80 mm</td>
                                        <td>12.5 mm</td>
                                    </tr>
                                </table>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OneProduct;
