import React, { useState, useEffect } from "react";
import "./css/ProductsPage.css";
import { Link } from "react-router-dom";
// import img3 from "./images/5J3A4340.jpg";
// import img4 from "./images/5J3A4482.jpg";
// import img5 from "./images/5J3A4277.jpg";
// import img6 from "./images/5J3A4504.jpg";
// import img7 from "./images/5J3A4269.jpg";
import { useTranslation } from "react-i18next";


function ProductsPage() {
    const { t } = useTranslation();
    return (
        <div
            className="products-page"
            // style={{ backgroundImage: "url(/images/5J3A4340-SECOND.jpg)" }}
        >
            <div className="products-page-container">
                <h1>{t("menu.products")}</h1>
                <div className="header-products">
                    <div className="header-products-product-page">
                        <Link to="/urunler/ahsap-kesen-celik-serit-testereler">
                            <img src={'/images/5J3A3907.jpg'} alt="" />
                            <div className="header-products-texts-page">
                                {/* <h2>Ahşap Kesen</h2> */}
                                {/* <h2>Çelik Şerit Testereler</h2> */}
                                <h2>{t("home-page.wood-cutting-steel-band-saw")}</h2>
                            </div>
                        </Link>
                    </div>

                    <div className="header-products-product-page">
                        <Link to="/urunler/et-ve-balik">
                            <img src={"/images/5J3A4504.jpg"} alt="" />
                            <div className="header-products-texts-page">
                                {/* <h2>Et ve Balık Testereleri</h2> */}
                                <h2>{t("home-page.meat-and-fish-saw")}</h2>
                            </div>
                        </Link>
                    </div>
                    <div className="header-products-product-page">
                        <Link to="/urunler/m42-bi-metal-serit-testere">
                            <img src={"/images/catalog/bimetal/5J3A4234.JPG"} alt="" />
                            <div className="header-products-texts-page">
                                {/* <h2>M42 Bi-Metal Şerit Testere</h2> */}
                                <h2>{t("home-page.m42-bi-metal-steel-band-saw")}</h2>
                            </div>
                        </Link>
                    </div>
                    <div className="header-products-product-page">
                        <Link to="/urunler/disleri-sertlestirilmis-testereler">
                            <img src={ "/images/5J3A4277.jpg"} alt="" />
                            <div className="header-products-texts-page">
                                <h2>
                                    {/* Dişleri Sertleştirilmiş, Bileyli ve Çaprazlı
                                    Ahşap Kesen Şerit Testereler */}
                                    <h2> {t("home-page.tempered-and-bevelled-wood-cutting-steel-band-saw")} </h2>   
                                </h2>
                            </div>
                        </Link>
                    </div>
                    <div className="header-products-product-page">
                        <Link to="/urunler/tct-elmas-uclu-daire-testereler">
                            <img src={"/images/catalog/tct-elmas/TCT-ELMAS-TESTERELER.png"} alt="" />
                            <div className="header-products-texts-page">
                                {/* <h2>TCT Elmas Uçlu Daire Testereler</h2> */}
                                <h2> {t("home-page.tct-diamond-edged-disc-saw")} </h2>
                            </div>
                        </Link>
                    </div>
                    <div className="header-products-product-page">
                        <Link to="/urunler/ahsap-testere-bileme-taslari">
                            <img src={ "/images/5J3A4340.jpg"} alt="" />
                            <div className="header-products-texts-page">
                                {/* <h2>Ahşap Testere Bileme Taşları</h2> */}
                                <h2>{t("home-page.wooden-saw-blades")}</h2>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductsPage;
