import React, { useEffect } from "react";
import "./App.css";
import Home from "./components/Home";
import HomeHeader from "./components/HomeHeader";
import Communication from "./components/Communication";
import Footer from "./components/Footer";
import About from "./components/About";
import ProductsPage from "./components/ProductsPage.js";
import Services from "./components/Services.js";
// import whatsapp from "./components/images/whatsapp.png";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import OneProduct from "./components/OneProduct.js";

// import img3 from "./components/images/5J3A4340.jpg";
// import img4 from "./components/images/5J3A4482.jpg";
// import img10 from "./components/images/5J3A4488.JPG";
// import img11 from "./components/images/5J3A4460.JPG";

import { useTranslation } from "react-i18next";

const App = () => {
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);




  return (
    <Router>
      <div className="App">
        <HomeHeader isFixedBarAlwaysOpen={true} />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/iletisim" element={<Communication />} />
          <Route exact path="/hakkimizda" element={<About />} />
          <Route exact path="/urunler" element={<ProductsPage />} />
          <Route exact path="/hizmetler" element={<Services />} />
          <Route
            exact
            path="/urunler/et-ve-balik"
            element={
              <OneProduct
                // backgroundImage={img9}
                backgroundImage={'/images/5J3A4469.jpg'}
                title={t("home-page.meat-and-fish-saw")}
                content={[
                  // "Kemikli, kemiksiz, donuk veya yumuşak et ve balık kesiminde kullanılan uçları sertleştirilmiş, yüksek performanslı testerelerdir. ",
                  t("home-page.meat-and-fish-saw"),
                ]}
                table="et-ve-balik"
                
                images={[
"/images/catalog/etbalik/5J3A4269.JPG",
"/images/catalog/etbalik/5J3A4270.JPG",
"/images/catalog/etbalik/IMGL7688.jpg",
"/images/catalog/etbalik/IMGL7696.jpg",
"/images/catalog/etbalik/IMGL7706.jpg",
"/images/catalog/etbalik/IMGL7741.jpg",
"/images/catalog/etbalik/IMGL7819.jpg",
"/images/catalog/etbalik/IMGL7821.jpg",
                  "/images/catalog/etbalik/IMGL7828.jpg",
                ]}
              />
            }
          />
          <Route
            exact
            path="/urunler/ahsap-kesen-celik-serit-testereler"
            element={
              <OneProduct
                backgroundImage={'/images/5J3A4488.JPG'}
                // title="Ahşap Kesen Çelik Şerit Testereler"
                title={t("home-page.wood-cutting-steel-band-saw")}
                content={[
                  // "Testere makinelerinde kullanılır.",
                  // "Ahşap kesen çelik şerit testerelerdir.",
                  t("products.used-in-saw-machines"),
                  t("products.wood-cutting-steel-band-saw"),
                  ,
                ]}
                // image={'/images/5J3A4482.jpg'}
                images={[
"/images/catalog/ahsap-kesen/5J3A3894.JPG",
"/images/catalog/ahsap-kesen/5J3A3908.JPG",
"/images/catalog/ahsap-kesen/5J3A4485.JPG",
"/images/catalog/ahsap-kesen/5J3A4487.JPG",
// "/images/catalog/ahsap-kesen/5J3A4488.JPG",
// "/images/catalog/ahsap-kesen/5J3A4492.JPG",
"/images/catalog/ahsap-kesen/IMGL7876.jpg",
"/images/catalog/ahsap-kesen/IMGL7877.jpg",
"/images/catalog/ahsap-kesen/IMGL7880.jpg",
// "/images/catalog/ahsap-kesen/IMGL7882.jpg",
"/images/catalog/ahsap-kesen/IMGL7890.jpg",
"/images/catalog/ahsap-kesen/IMGL7971.jpg",
                ]}

                table="ahsap-kesen"
              />
            }
          />

          <Route
            exact
            path="/urunler/m42-bi-metal-serit-testere"
            element={
              <OneProduct
                // backgroundImage={img3}
                backgroundImage={"/images/catalog/bimetal/5J3A4234-edited.JPG"}
                // title="M42 Bi-Metal Şerit Testere"
                title={t("home-page.m42-bi-metal-steel-band-saw")}
                content={[
                  // "Metal kesen şerit testere makinelerinde kullanılan çok amaçlı şerit testerelerdir.",
                  t("products.multiple-purpose-steel-band-saw"),
                  ,
                ]}
                images={[
"/images/catalog/bimetal/5J3A4234.JPG",
"/images/catalog/bimetal/IMGL7829.jpg",
"/images/catalog/bimetal/IMGL7836.jpg",
"/images/catalog/bimetal/IMGL7837.jpg",
"/images/catalog/bimetal/IMGL7985.jpg",
"/images/catalog/bimetal/IMGL7991.jpg",
// "/images/catalog/bimetal/IMGL7993.jpg"
                ]}
              />
            }
          />

          <Route
            exact
            path="/urunler/tct-elmas-uclu-daire-testereler"
            element={
              <OneProduct
                // backgroundImage={img3}
                backgroundImage={'/images/catalog/tct-elmas/KRONBERG-ELMAS-TESTERE-edit.png'}
                // title="TCT Elmas Uçlu Daire Testereler"
                title={t("home-page.tct-diamond-edged-disc-saw")}
                content={[
                  "",
                  // "Ahşap, MDF, suntalam, laminant, alüminyum ve plastik kesiminde kullanılan dairesel testerelerdir.",
                  t("products.wooden-mdf-suntalam"),
                  ,
                ]}
                images={['/images/catalog/tct-elmas/tct-elmas.png']}
              />
            }
          />
          <Route
            exact
            path="/urunler/ahsap-testere-bileme-taslari"
            element={
              <OneProduct
                // backgroundImage={img3}
                backgroundImage={"/images/5J3A4340-SECOND.jpg"}
                // title="Ahşap Testere Bileme Taşları"
                title={t("home-page.wooden-saw-blades")}
                content={["Dairesel testerelerin, şerit testereler, alternatif testerelerin bilenmesi, malzemeyi yakmayan uzun ömürlü, rahat kesim sunan bileme taşlarıdır.",
                   "<br>",
                   "<span class='one-product-content-texts-content-title'>Form D Çanak</span>",
                   "<span class='one-product-content-texts-content-description'> Dairesel testerelerde yüksek dayanıklılık ve keskinlik sağlayan, malzemeyi ısıtmadan hassas kesim için optimize edilmiş çanak formlu bileme taşı.</span>",
                   "<br>",
                   "<span class='one-product-content-texts-content-title'>Form E Çanak</span>",
                   "<span class='one-product-content-texts-content-description'>Şerit ve alternatif testerelerde yumuşak kesim performansı sunan, uzun ömürlü yapısıyla endüstriyel kullanıma uygun özel tasarımlı bileme taşı.</span>",
                   "<br>",
                   "<span class='one-product-content-texts-content-title'>NKV1</span>",
                   "<span class='one-product-content-texts-content-description'>Çok yönlü kullanım için ideal, dairesel ve alternatif testerelerin bilenmesinde yakma riski olmadan rahat kesim garantisi veren profesyonel bileme taşı.</span>",
                   "<br>",
                   "<span class='one-product-content-texts-content-title'>EKR</span>",
                   "<span class='one-product-content-texts-content-description'>Özellikle çelik malzemelerde yüksek verimli taşlama için tasarlanmış, aşınmaya dirençli yapısıyla zorlu kesim süreçlerinde kullanılan dayanıklı taş.</span>"
                ]}
                // image={'/images/5J3A4482.jpg'}
                images={[
                  "/images/catalog/tas/form-d.webp",
                  "/images/catalog/tas/form-e.webp",
                  "/images/catalog/tas/nkv1.webp",
                  "/images/catalog/tas/celik-taslama.webp",
                ]}

                descriptions={[
                  "Form D Çanak",
                  "Form E Çanak",
                  "NKV1",
                  "EKR",
                ]}

              />
            }
          />
          <Route
            exact
            path="/urunler/disleri-sertlestirilmis-testereler"
            element={
              <OneProduct
                backgroundImage={'/images/5J3A4461.jpg'}
                // title="Dişleri Sertleştirilmiş, Bileyli ve Çaprazlı Ahşap Kesen Şerit
                //   Testereler"
                title={t("home-page.tempered-and-bevelled-wood-cutting-steel-band-saw")}
                content={["", "", ,]}
                image={'/images/5J3A4482.jpg'}
                table="disleri-sertlestirilmis-testereler"
                images={[
"/images/catalog/woodmayer/5J3A4461.JPG",
"/images/catalog/woodmayer/IMGL7764.jpg",
"/images/catalog/woodmayer/IMGL7772.jpg",
"/images/catalog/woodmayer/IMGL7773.jpg",
"/images/catalog/woodmayer/IMGL7776.jpg",
"/images/catalog/woodmayer/IMGL7784.jpg",
"/images/catalog/woodmayer/IMGL7798.jpg",
"/images/catalog/woodmayer/IMGL7803.jpg",
"/images/catalog/woodmayer/IMGL7806.jpg",
                ]}
              />
            }
          />
        </Routes>
        <Footer />
        <div className="whatsapp">
          <a href="https://wa.me/message/OLUBGZ3MMWBTI1" target="new_blank">
            <img src={"/images/whatsapp.png"} alt="" />
          </a>
        </div>
      </div>
    </Router>
  );
};

export default App;
